<template>
  <div class="page-body">
    <!-- <NavBar />
      <NavBarLeft /> -->
    <TitleBar :title="$t('addUserInfo')" />
    <v-container>
      <v-row class="mt-5 d-flex justify-content-center">
        <v-col col sm="12" md="12" lg="12">
          <v-alert
            outlined
            text
            elevation="4"
            type="error"
            v-if="errorMessage"
            :show="dismissCountDown"
            dismissible
            @dismiss-count-down="countDownChanged"
          >
            {{ errorMessage }}
          </v-alert>
          <v-alert
            outlined
            text
            elevation="4"
            type="success"
            v-if="successMessage"
            :show="dismissCountDown"
            dismissible
            @dismiss-count-down="countDownChanged"
          >
            {{ successMessage }}
          </v-alert>
          <v-stepper v-model="currentStep" min-height="100%">
            <v-stepper-header>
              <!-- <v-stepper-step :complete="currentStep > 1" step="1">{{
                $t("account_type")
              }}</v-stepper-step>
              <v-divider></v-divider> -->
              <v-stepper-step :complete="currentStep > 1" step="1">{{
                $t("personal_information_create_user")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 2" step="2">{{
                $t("contact_information")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="currentStep > 3" step="3">{{
                $t("source_of_income_create_user")
              }}</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <!-- <v-stepper-content step="1">
                <account-type
                  :addProfilePayload="addProfilePayload"
                  @onAccountNext="onClickNext"
                  @onAccounSelect="onSelectAccountType"
                />
              </v-stepper-content> -->
              <v-stepper-content step="1">
                <basic-details
                  :addProfilePayload="addProfilePayload"
                  @onNextClick="onClickNextSecondSection"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>
              <v-stepper-content step="2">
                <address-section
                  :addProfilePayload="addProfilePayload"
                  @onNextClick="onClickNextSecondSection"
                  @onClickBack="onClickBack"
                />
              </v-stepper-content>
              <v-stepper-content step="3">
                <SourceOfIncome
                  :addProfilePayload="addProfilePayload"
                  :loader="loader"
                  :user_type="user_type"
                  @onClickNext="onCreateBeneficiary"
                  @onClickBack="onClickBack"
                ></SourceOfIncome>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import NavBar from "@/components/navigations/NavBar.vue";
// import NavBarLeft from "@/components/navigations/NavBarLeft.vue";
import TitleBar from "@/components/navigations/TitleBar.vue";
// import AccountType from "@/components/CreateUserIND/AccountType/index.vue";
import BasicDetails from "@/components/CreateUserIND/BasicDetails/index.vue";
import SourceOfIncome from "@/components/CreateUserIND/SourceOfIncome/index.vue";
import AddressSection from "@/components/CreateUserIND/AddressSection/index.vue";
import userservice from "@/services/UserService/userservice";
import remitteService from "@/services/RemitteService/remitteService";
import alerts from "@/mixins/alerts";

export default {
  name: "Beneficiary",
  mixins: [alerts],
  components: {
    // NavBar,
    // NavBarLeft,
    TitleBar,
    // AccountType,
    BasicDetails,
    AddressSection,
    SourceOfIncome,
  },
  data() {
    return {
      steps: [
        { text: this.$t("accountType") },
        { text: this.$t("basicDetails") },
        { text: "Contact Information" },
        { text: "Source Of Income" },
      ],
      currentStep: 1,
      partner_user: null,
      loader: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      user_type: "",
      addProfilePayload: {
        user_type: "IND",
        first_name: "",
        last_name: "",
        middle_name: "",
        email_id: "",
        dob: null,
        gender: "",
        fund_source: "",
        occupation_code: "",
        employer_name: "",
        income_range: "",
        address: "",
        country_of_birth: null,
        zip_code: "",
        district: "",
        country_code: "MYS",
        state_code: "",
        city_code: "",
        nationality_code: "",
        source_of_fund_code: "",
        sp_referral_code: "",
      },
      beneficiary: {
        pin_code: "",
        adline1: "",
        adline2: "",
        fname: "",
        lname: "",
        type: "IND",
        name: "",
        relationship: "546",
        phone: "",
        email: "",
        address: "",
        country: {},
        state: {},
        city: {},
        account: "",
        ifsc: "",
        confirmAccount: "",
        countryCod: "",
        remitteId: "",
      },
      errorMessage: "",
      successMessage: "",
    };
  },
  mounted() {
    this.$emit("getProfile");
    this.partner_user = this.$getLocalStorage("is_partner");
    if (this.partner_user) {
      this.getEVPartnerUserDetails();
    }
  },
  methods: {
    async getEVPartnerUserDetails() {
      const responseData = await userservice.getEvolteUser();
      let userDetails = (responseData.data && responseData.data.data[0]) || {};
      console.log("userDetails", userDetails);
      this.addProfilePayload = { ...userDetails };
    },
    onClickNext: function () {
      this.currentStep++;
    },
    onClickBack: function () {
      this.currentStep--;
    },
    onSelectAccountType(type) {
      this.beneficiary.type = type;
      this.addProfilePayload.user_type = type;
      this.user_type = type;
    },
    onClickNextSecondSection() {
      this.currentStep++;
    },
    async onCreateBeneficiary() {
      const remiteeData = {
        Remittee_name: this.beneficiary.fname + " " + this.beneficiary.lname,
        registration_type_cd: this.beneficiary.type,
        address: this.beneficiary.adline1 + ", " + this.beneficiary.adline2,
        pin_code: this.beneficiary.pin_code,
        phone_no: this.beneficiary.phone,
        email_id: this.beneficiary.email,
        Remittee_country: this.beneficiary.country.name,
        Country_code: this.beneficiary.country.code,
        country_id: this.beneficiary.country.id,
        Remittee_city: this.beneficiary.city.name,
        Remittee_state: this.beneficiary.state.name,
        State_Code: this.beneficiary.state.code,
        state_id: this.beneficiary.state.id,
        city_Code: "",
        city_id: this.beneficiary.city.id,
        relation_with_Remitter_cd: this.beneficiary.relationship,
        payment_mode_cd: "FPX",
        bank_ac_no: this.beneficiary.account,
        swift_no: this.beneficiary.ifsc,
        bic_cd: "",
      };
      try {
        this.loader = true;
        // const responseData = await this.$store.dispatch(
        //   "Remittee/createRemitte",
        //   remiteeData
        // );
       const  responseData = await remitteService.createRemittee(
        remiteeData
          );
        if (responseData && responseData.data.status_code == 200) {
          this.loader = false;
          this.errorMessage = "";
          this.successMessage = "Beneficiary user created successfully";
          // this.showAlertSuccess(responseData.data.message);
          this.dismissCountDown = this.dismissSecs;
          setTimeout(() => {
            this.$router.push({ name: "Beneficiary" });
          }, 2000);
        } else if (responseData.response.data.message) {
          this.loader = false;
          this.errorMessage = responseData.response.data.message;
          // this.showAlertError(responseData.data.message);
          this.dismissCountDown = this.dismissSecs;
          this.successMessage = "";
        } else {
          this.errorMessage = "User is not authorised please login the user";
          // this.showAlertError('User is not authorised please login the user');
          this.dismissCountDown = this.dismissSecs;
          this.successMessage = "";
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.dismissCountDown = this.dismissSecs;
        this.successMessage = "";
        this.errorMessage = "API is not working please wait sometime";
        // this.showAlertError('API is not working please wait sometime');
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
::v-deep ul {
  list-style-type: none !important;
}
::v-deep h4 {
  color: $txt;
  text-align: center;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
.card {
  border: none;
}
::v-deep label {
  color: $lightslategrey !important;
  margin-bottom: 0 !important;
}
::v-deep .btn-light {
  border: 1px solid $lightgrey !important;
}
.floating-placeholder-name {
  color: red;
  margin-top: -12px;
  font-size: 14px;
  text-align: -webkit-left;
}
.color-error {
  background-color: rgb(240, 50, 50);
}
</style>
